import React from 'react'
import Address from './Address'
import './AllAddresses.css'

const AllAddresses = (props) => {
    return (
        <div className="all-addresses-container">
            <h1>All Upcoming Meetings</h1>
            <Address addresses={props.addresses}></Address>
        </div>
    )

}

export default AllAddresses