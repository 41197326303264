import React from 'react';
import './Resources.css';
import VideoVaultPlaylist from './VideoVaultPlaylist';
import understandYourBibleLeaflet from '../assets/understand-your-bible-leaflet.png';


const Resources = () => {
    return(
        <div className="resources-container">
            <h1>Links &amp; Resources</h1>
            {/* <h2>Bible Truth and Prophesy YouTube Channel - Recent Uploads</h2>
            <VideoVaultPlaylist /> */}
            <div class="special-videos">
                <h2>Easter Special Videos</h2>
                <p class="headline">Redditch Christadelphians would like to invite you to view three videos online provided by the Gospel Online YouTube channel:</p>
                <p>On Friday April 2nd - The day Jesus died on the cross</p>
                <iframe width="840" height="472" src="https://www.youtube.com/embed/xpGurD2VgNc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <p>On Sunday April 4 - The day Jesus rose from the dead</p>
                <iframe width="840" height="472" src="https://www.youtube.com/embed/-1hpwYyOo88" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <p>On Monday April 5 - Evidence for the resurrection</p>
                <iframe width="840" height="472" src="https://www.youtube.com/embed/21Y-9ug1c0M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <p style={{color: 'grey'}}>All three videos will be uploaded at 8pm on the dates shown but can be viewed at any time after that. You can view them here when live or directly on the <a href="https://www.youtube.com/gospelonlineuk">Gospel Online YouTube channel</a>.</p>
            </div>
            <div class="understand-your-bible">
                <a href="https://understandyourbible.org/about-our-reading-plan"><img className="understand-your-bible-leaflet" src={understandYourBibleLeaflet} /></a>
                <div class="uyb-description">
                <p class="uyb-header"><i>We want to help you make sense of life by looking at what the Bible text says.
Each daily chapter page has:</i></p>

                <ul>
                    <li>a brief introduction to the chapter</li>
                    <li>the text of the World English Bible British Edition for the daily chapter</li>
                    <li>questions which are answered by a verse in the chapter</li>
                    <li>summary points for the chapter</li>
                    <li>a link to a relevant Bible theme or more relevant information</li>
                </ul>
                <a href="https://understandyourbible.org/about-our-reading-plan"><button>Start your plan today</button></a>
                </div>
            </div>

            <div class="links">
                <a href="https://www.gospelonline.co.uk">
                    <div>
                        <h3>Gospel Online</h3>
                        <p>Bible talks on Christadelphian basic beliefs</p>
                    </div>
                </a>
                <a href="https://www.thechristadelphians.org.uk/">
                    <div>
                        <h3>CALS</h3>
                        <p>For a list of booklets to send for on topics that might interest you</p>
                    </div>
                </a>
                <a href="https://www.thechristadelphian.com">
                    <div>
                        <h3>Christadelphian Office</h3>
                        <p>Bibles, hymn books, study books and more avaiable for sale here</p>
                    </div>
                </a>
                <a href="https://www.dailyreadings.org.uk">
                    <div>
                        <h3>Daily Readings</h3>
                        <p>Listen or read online the daily readings following the Christadelphian reading planner</p>
                    </div>
                </a>
                <a href="https://gladtidingsmagazine.org">
                    <div>
                        <h3>Glad Tidings Magazine</h3>
                        <p>A monthly Christadelphian magazine</p>
                    </div>
                </a>
                <a href="https://www.thechristadelphian.com/about-us/faith-alive-magazine">
                    <div>
                         <h3>Faith Alive Magazine</h3>
                         <p>A monthly Christadelphian young peoples magazine</p>
                    </div>
                </a>
                <a href="https://www.thechristadelphian.com/about-us/the-christadelphian-magazine">
                    <div>
                        <h3>Christadelphian Magazine</h3>
                        <p>A monthly Christadelphian magazine</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Resources