import React from 'react';
import ReactGA from "react-ga";

import HomepageContainer from './HomepageContainer';
import AllAddresses from '../Components/AllAddresses';
import Beliefs from '../Components/Beliefs';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';
import ContactForm from '../Components/ContactForm';
import ContactConfirmation from '../Components/ContactConfirmation';
import Resources from '../Components/Resources';
import './AppContainer.css';
import { API_URL } from '../utils/constants';

ReactGA.initialize('UA-186391313-1');

class AppContainer extends React.Component {
    constructor() {
        super()
        this.state = {
            addresses: [],
            form: {
                title: '',
                speaker: '',
                service: '',
                date: ''
            },
            isMobile: '',
            menuShow: true
        }
        this.toggleMenu = this.toggleMenu.bind(this)
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        fetch(`${API_URL}/api/addresses`)
            .then(res => res.json())
            .then(addresses => this.setState({
                addresses: addresses
            }))
            .catch(err => console.error);

        if (document.body.clientWidth < 700) {
            this.setState({
                isMobile: true,
                menuShow: false
            })
        }
        else {
            this.setState({
                isMobile: false
            })
        }
    }

    toggleMenu() {
        this.state.menuShow === false ?
        this.setState({
            menuShow: true
        })
        :
        this.setState({
            menuShow: false
        })
    }

    render() {
        return (
            <Router>
                <Header menuShow={this.state.menuShow} toggleMenu={this.toggleMenu} />
                <Switch>
                    <div className="page-body">
                        <Route path="/" exact>
                            <HomepageContainer addresses={this.state.addresses} />
                        </Route>

                        <Route path="/meetings">
                            <AllAddresses addresses={this.state.addresses} />
                        </Route>
                        <Route path="/beliefs">
                            <Beliefs />
                        </Route>
                        <Route path="/contact">
                            <ContactForm />
                        </Route>
                        <Route path="/resources">
                            <Resources />
                        </Route>
                        <Route path="/thankyou">
                            <ContactConfirmation />
                        </Route>
                    </div>
                </Switch>
                <Footer />
            </Router>
        )
    }
}
export default AppContainer