import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';
import './Footer.css'

const Footer = () => {
    return (
        <div className='footer-container'>
            <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/meetings"> Meeting Calendar</Link></li>
            <li><Link to="/beliefs">Our Beliefs</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/resources">Links &amp; Resources</Link></li>
            </ul>
            <address>
                303a Birchfield Road<br></br>
                Redditch<br></br>
                B97 4NB<br></br>
            </address>
        </div>
    )
}

export default Footer