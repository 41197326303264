import React from 'react';
import Header from './Header';
import Footer from './Footer';

const ContactConfirmation = () => {
    return (
        <div style={{padding: "30px"}}>
            <p style={{fontWeight: "bold"}}>Form Submission Successful</p>
            <p>Thank you for getting in touch with us, someone will get back to you soon!</p>
        </div>
    )
}

export default ContactConfirmation;