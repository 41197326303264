import React from 'react'
import './Beliefs.css'

const Beliefs = () => {
    return (
        <div className="beliefs-container">
            <div className="gospel-video-container"> 
                <iframe style={{marginRight: '30px'}} width="560" height="315" src="https://www.youtube.com/embed/jRAJwqjFA3Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ilzanubsRfQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/jAsXzp3Zd7Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                {/* <iframe className="gospel-video" src="https://www.youtube.com/embed/jRAJwqjFA3Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </div>

            <h1>Who are the Christadelphians and what are our beliefs?</h1>
            <p>
                The Christadelphians are an international bible-based community made up of people from many backgrounds and walks of life. 
                We are brought together by one common belief, that we all recognise there is one God and that he has a plan and purpose for those who follow him. 
                We try to model our lives on the examples set by Gods son Jesus Christ who sacrificed himself for our sins. Below are some key points about our beliefs:
            </p>
            <p>We follow the teachings of the Lord Jesus Christ and uphold doctrines believed by the Apostles (those Believers who received instruction from the Lord Jesus Christ Himself)</p>
            <h2>We believe:</h2>
            <ul>
                <li>There is One God, who created and sustains all things</li>
                <li>The Bible is God's message to the World, and is as relevant today as is ever has been</li>
                <li>Jesus Christ is the Son of God, our saviour and coming king</li>
                <li>God's Kingdom will be set up on the Earth</li>
                <li>That Jesus will return to establish that Kingdom</li>
                <li>That those who accept Jesus Christ and his teachings will be accepted by him when he returns</li>
                <li>That Baptism into Christ is a necessary step to salvation</li>
            </ul>

            <p>To find out more, get in touch with us via the contact form <a href="/contact">here</a> or take a look at <a href="resources">our resources</a></p>
        </div>
    )
}

export default Beliefs