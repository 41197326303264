import React, { Component } from 'react';
import './VideoVaultPlaylist.css';

class VideoVaultPlaylist extends Component {

    constructor() {
        super();
        this.state = {
            videos: [],
        }
    }

    componentDidMount() {
        fetch('https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCZbnw4OnUQ5RsvkvlRySYTw&maxResults=6&order=date&type=video&key=AIzaSyCyBZY2O3U3eEMzjKcx_O7oRD_kHWep8lI')
            .then(res => res.json())
            .then(res => this.setState({ videos: res.items }));
    }
    render() {
        return (
            <div className="videos-container">
            {this.state.videos.map(video => (
                <div className="video-container">
                    <iframe className="youtube-player" id="ytplayer" type="text/html"
                        src={`https://www.youtube.com/embed/${video.id.videoId}?autoplay=0`}
                        frameborder="0" />
                </div>
            ))}
            </div>
        )
    }
}

export default VideoVaultPlaylist;