import React from 'react';
import Address from './Address';
import './ThisWeeksAddresses.css';

const ThisWeeksAddresses = (props) => {
    const upcomingAddresses = props.addresses.slice(0,3)

    return (
        <div className="upcoming-services">
            <h2>Upcoming Services:</h2>
            <Address addresses={upcomingAddresses}></Address>
        </div>
    )
}

export default ThisWeeksAddresses