import React from 'react'
import './Address.css'

function getMonthName (date) {
    let month = ''
    let dateCut = date.substring(5, 7);
    switch(dateCut) {
        case "01":
            month = "January"
            break;
        case "02":
            month = "February"
            break;
        case "03":
            month = "March"
            break;
        case "04":
            month = "April"
            break;
        case "05":
            month = "May"
            break;
        case "06":
            month = "June"
            break;
        case "07":
            month = "July"
            break;
        case "08":
            month = "August"
            break;
        case "09":
            month = "September"
            break;
        case "10":
            month = "October"
            break;
        case "11":
            month = "November"
            break;
        case "12":
            month = "December"
            break;
    }

    return month
}

const Address = (props) => {
    return (
        props.addresses.map(address => {
            let dateDay = address.date.substring(8, 10);
            return (
                <div className="address-container">
                    <div className="calendar-container">
                        <div className="calendar">
                            <p className="day">{dateDay}</p>
                            <p className="month">{getMonthName(address.date)}</p>
                        </div>
                    </div>

                    <div className="address-sub-container">
                        <div className="row">
                            <p><b>Service:</b></p>
                            <p>{address.service}</p>
                        </div>
                        <div className="row">
                            <p><b>Date:</b></p>
                            <p>{address.date}</p>
                        </div>
                        <div className="row">
                            <p><b>Time:</b></p>
                            <p>{address.time}</p>
                        </div>
                    </div>
                    <div className="address-sub-container">
                        <div className="row">
                            <p><b>Speaker:</b></p>
                            <p>{address.speaker}</p>
                        </div>
                        <div className="row">
                            <p><b>Title:</b></p>
                            <p>{address.title}</p>
                        </div>
                    </div>
                    {window.location.pathname === "/manage" ? <button onClick={() => props.deleteAddress(address._id)}>Delete</button> : ""}
                </div>
            )
        })
    )
}

export default Address