import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './ContactForm.css';
import { API_URL, RECAPTCHA_SITE_KEY } from '../utils/constants';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recaptchaToken: false,
            formSubmitted: false,
            name: '',
            phone: '',
            email: '',
            message: '',
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            message: this.state.message,
            recaptchaToken: this.recaptchaToken,
        }

        // if (this.state.recaptchaPassed) {
        fetch('/contact-form-submit.php', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        })

        this.setState({ formSubmitted: true })
        // window.location="/thankyou"
        // }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    handleRecaptchaChange = (token) => {
        this.setState({ recaptchaToken: token });
    }

    render() {
        return (
            <div className="contact-form-container">
                <h1>Contact Us</h1>
                <p>To get in touch with with us please fill out the contact form below and one of our members will promptly contact you via your preferred means(please state this in your message)</p>
                <form onSubmit={this.handleSubmit} method="POST" target="/" >
                    <input name="name" type="text" placeholder="Name" onChange={this.handleChange} />
                    <input name="email" type="email" placeholder="Email" onChange={this.handleChange} />
                    <input name="phone" type="text" placeholder="Phone" onChange={this.handleChange} />
                    <input name="message" type="textarea" placeholder="Message" onChange={this.handleChange} />
                    
                    <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={this.handleRecaptchaChange} />
                    {this.state.recaptchaToken ? <input className="submit" type="submit"></input> : ''}
                </form>

                {this.state.formSubmitted ? (
                    <div>
                        <h2>Form submitted successfully</h2>
                    </div>
                ) : ''
                }
            </div>
        )
    }
}

export default ContactForm