import React from 'react';
import './Header.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';
import { API_URL } from '../utils/constants';

const Header = (props) => {
    return (
        <div className="header-container">
            <h1>Redditch Christadelphians</h1>
            {props.menuShow === true ?
                <nav>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        {/*<li><Link to="/meetings"> Meeting Calendar</Link></li>*/}
                        <li><Link to="/beliefs">Our Beliefs</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                        <li><Link to="/resources">Links &amp; Resources</Link></li>
                        {/*<li><a href={API_URL} >Members Area</a></li>*/}
                    </ul>
                </nav>
                : ''
            }
            {props.menuShow === false ? <p className="menu-button" onClick={props.toggleMenu} >Menu</p> : ''}
        </div>
    )
}

export default Header