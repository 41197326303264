import React from 'react'
import ReactGA from "react-ga";
import ThisWeeksAddresses from '../Components/ThisWeeksAddresses'
import './HomepageContainer.css'

const HomepageContainer = (props) => {
    return (
        <div className="homepage-container">
            <div className="hero">
                
            </div>
            <div className="who-are-we">
                <h2 className="welcome">Welcome</h2>
                <h2>Who are we?</h2>
                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/ilzanubsRfQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                <p>We are a Christadelphian Ecclesia based in Redditch, our beliefs are founded on reading and studying the Bible and its teachings. We meet 3 times a week in the name of the Lord God and to remember the great sacrifice his Son the Lord Jesus Christ made to redeem us. We believe Jesus will return to the earth and establish a kingdom for his Father, God.</p>
            </div>
            <ThisWeeksAddresses addresses={props.addresses}></ThisWeeksAddresses>
            <div className="where">
                <h2>Where to find us:</h2>
                <address>303a Birchfield Road<br></br> Redditch<br></br>B97 4NB</address>
            </div>
            <div className="google-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2439.998218020018!2d-1.962440447695053!3d52.29788680151788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870c1d40668f835%3A0xfd6c175c3f6a27ab!2sRedditch%20Christadelphian%20Hall!5e0!3m2!1sen!2suk!4v1580838725461!5m2!1sen!2suk" width="100%" height="450" frameborder="0" allowfullscreen=""></iframe>
            </div>
            <div className="service-information">
                <h2>Service Information:</h2>
                <h3>Sunday Breaking of Bread</h3>
                <p>We meet at 11am on a Sunday to break bread remembering Jesus's sacrifice and to learn about the Bible as presented by a brother in Christ. All are welcome to this service and baptised Christadelphians can partake in breaking bread. </p>
                <h3>Sunday Evening Talk</h3>
                <p>On Sunday evenings at 6pm we have light lecture on the Bible given by a speaker on a given topic, often these feature visual presentations. After the talk we share tea and biscuits. All welcome.</p>
                <h3>Tuesday Bible Class</h3>
                <p>We meet on Tuesday nights at 8pm for an in depth Bible study. All welcome.</p>
            </div>
        </div>
    )
}

export default HomepageContainer